import TYPES from '@/types';
import { v4 } from 'uuid';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';
import { currencyFormat } from '@/vue-app/utils/currency';

import Vue from 'vue';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzRecurringContributionsQuery,
} from '@/modules/my-investment/allianz/recurring-contributions/application/queries';
import {
  SearchPeriodicityOfContributionsQuery,
} from '@/modules/my-investment/catalogs/allianz/periodicity-of-contribution/application/queries';
// Application
import { MonthlyCollectionDaysService } from '@/modules/my-investment/allianz/recurring-contributions/application/services';

// Domain
import {
  MonthlyCollectionDayOptionEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/monthly-collection-day-option-entity';
import {
  PeriodicityOfContributionEntity,
} from '@/modules/my-investment/catalogs/allianz/periodicity-of-contribution/domain/entities/periodicity-of-contribution-entity';
import {
  RecurringContributionDistributionEntity,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/entities/recurring-contribution-distribution-entity';
import {
  AllianzRecurringContributionsEntity,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/entities/allianz-recurring-contributions-entity';
import {
  SetRecurringContributionsStateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/set-recurring-contributions-state-manager';
import {
  BankNameFormatter,
} from '@/modules/my-investment/catalogs/allianz/financial-institution/domain/services';
import Inject from '@/modules/shared/domain/di/inject';
import { requiredRule } from '@/vue-app/utils/form-rules';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzDepositRecurringViewModel {
  @Inject(TYPES.MONTHLY_COLLECTION_DAYS_SERVICE)
  readonly monthly_collection_days_service!: MonthlyCollectionDaysService;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_PERIODICITY_OF_CONTRIBUTION_QUERY)
  private readonly search_periodicity_of_contributions!: SearchPeriodicityOfContributionsQuery;

  @Inject(TYPES.GET_ALLIANZ_RECURRING_CONTRIBUTIONS_QUERY)
  private readonly get_allianz_recurring_contributions_query!:
    SearchAllianzRecurringContributionsQuery;

  @Inject(TYPES.SET_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly set_recurring_contributions_state_manager!:
    SetRecurringContributionsStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view: Vue;

  private readonly customer_id = sessionStorage.getItem('user_id');

  i18n_namespace = 'components.allianz-dashboard.deposits.recurring_deposit';

  is_valid_form = false;

  multiple_is_valid_form = false;

  readonly minimum_amount = 500;

  readonly maximum_amount = 50000;

  maximum_total_amount = 100000;

  input_rules = {
    source_account: [
      requiredRule,
    ],
    new_collection_day: [
      requiredRule,
    ],
    new_amount: [
      requiredRule,
      (value: string) => (
        parseCurrencyToNumber(value) >= this.minimum_amount
        || this.translate_errors('utils.form-rules.minimum_error', {
          value: `${currencyFormat(this.minimum_amount)} MXN`,
        })
      ),
      (value: string) => (
        parseCurrencyToNumber(value) <= this.maximum_total_amount
        || this.translate_errors('utils.form-rules.maximum_error', {
          value: `${currencyFormat(this.maximum_total_amount)} MXN`,
        })
      ),
    ],
    multiple_new_amount: [
      requiredRule,
      (value: string) => (
        parseCurrencyToNumber(value) >= this.minimum_amount
        || this.translate_errors('utils.form-rules.minimum_error', {
          value: `${currencyFormat(this.minimum_amount)} MXN`,
        })
      ),
      (value: string) => (
        parseCurrencyToNumber(value) <= this.maximum_amount
        || this.translate_errors('utils.form-rules.maximum_error', {
          value: `${currencyFormat(this.maximum_amount)} MXN`,
        })
      ),
    ],
    periodicity: [
      requiredRule,
    ],
  };

  monthly_collection_days: Array<MonthlyCollectionDayOptionEntity> = [];

  second_monthly_collection_days: Array<MonthlyCollectionDayOptionEntity> = [];

  periods: Array<PeriodicityOfContributionEntity> = [];

  labels = {
    previous_contribution: '',
    previous_amount: '',
    first_payment_day: '',
    first_amount: '',
  }

  show_back_to_one_contribution = false;

  show_want_multiple_contributions = true;

  amount_exceeded = false;

  minimum_for_multiple_contributions_not_covered = false;

  recurring_contributions: AllianzRecurringContributionsEntity = {
    contributions: [],
    total_contribution: 0,
    home_desired: false,
  }

  set_state = this.set_recurring_contributions_state_manager.state;

  home_desired = false;

  constructor(view: Vue) {
    this.view = view;
  }

  get btn_continue_disabled() {
    return !this.is_valid_form || this.minimum_for_multiple_contributions_not_covered;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  translate_errors = (message: string, values?: Values) => this.translator.translate(`${message}`, values);

  setLabelsOneContribution = () => {
    this.labels.previous_contribution = this.translate('payment_date');
    this.labels.previous_amount = this.translate('current_contribution_amount');
    this.labels.first_payment_day = this.translate('new_payment_date');
    this.labels.first_amount = this.translate('new_contribution_amount');
  }

  setLabelsMultipleContributions = () => {
    this.labels.previous_contribution = this.translate('last_payment_date');
    this.labels.previous_amount = this.translate('last_contribution_amount');
    this.labels.first_payment_day = this.translate('first_payment_date');
    this.labels.first_amount = this.translate('contribution_amount');
  }

  loadMonthlyCollectionDays = () => {
    this.monthly_collection_days = this.monthly_collection_days_service.load();
  }

  loadPeriods = async () => {
    this.periods = await this.search_periodicity_of_contributions.execute();
  }

  appendContribution = (
    id: string,
    distributions: Array<RecurringContributionDistributionEntity> = [],
    amount = '0',
    collection_day = 0,
  ) => {
    const new_distributions = distributions.map((distribution) => ({
      ...distribution,
      recurrent_assigned_amount: 0,
    }));
    this.set_state.item.contributions.push({
      id,
      amount,
      collection_day,
      distributions: new_distributions,
      periodicity: 'monthly',
      suspend: false,
    });
  }

  loadAccountInformation = async () => {
    const {
      bank, account, policy_number_issued,
    } = await this.get_allianz_account_query.execute({ customer_id: this.customer_id });
    const formatted_bank_name = BankNameFormatter.format(bank);
    this.set_state.item.source_account = `${formatted_bank_name} **** ${account.substr(account.length - 4)}`;
    this.set_state.item.policy_number = policy_number_issued;
    const monthly_period = this.periods.find((period) => period.descPeriod === '');
    this.set_state.item.periodicity = monthly_period?.descPeriod || 'MENSUAL';
  }

  loadRecurringContributions = async () => {
    this.set_state.item.contributions = [];
    await this.loadAccountInformation();
    this.recurring_contributions = await this
      .get_allianz_recurring_contributions_query.execute();
    this.home_desired = this.recurring_contributions.home_desired;
    this.set_state.item.home_desired = this.recurring_contributions
      .home_desired;
    if (this.recurring_contributions.home_desired) {
      this.set_state.item.current_total_contribution = `${currencyFormat(this.recurring_contributions
        .total_contribution)} MXN`;
      if (this.recurring_contributions.contributions.length > 0) {
        const first_contribution = this.recurring_contributions.contributions[0];
        this.set_state.item.last_contribution_amount = first_contribution.amount.toFixed(2);
        this.set_state.item.last_collection_day = first_contribution
          .collection_day;
        this.appendContribution(first_contribution.id, first_contribution.distributions);
        this.set_state.item.contributions[0].collection_day = first_contribution.collection_day;
      } else {
        this.appendContribution(v4());
      }

      if (this.recurring_contributions.contributions.length > 1) {
        this.setLabelsMultipleContributions();
        this.show_back_to_one_contribution = true;
        this.show_want_multiple_contributions = false;
        this.set_state.item.wants_more_than_one_contribution = false;
        this.set_state.item.have_more_than_one_contribution = true;
        this.appendContribution(
          this.recurring_contributions.contributions[1].id,
          this.recurring_contributions.contributions[1].distributions,
          this.recurring_contributions.contributions[1].amount.toString(),
          this.recurring_contributions.contributions[1].collection_day,
        );
        this.set_state.item.contributions[0].amount = this.recurring_contributions.contributions[0]
          .amount.toString();
        this.set_state.item.contributions[0].collection_day = this.recurring_contributions
          .contributions[0].collection_day;
        this.maximum_total_amount = this.maximum_amount;
        this.loadAvailableDaysOnSecondCollectionDay();
      } else {
        this.show_want_multiple_contributions = true;
        this.setLabelsOneContribution();
        this.maximum_total_amount = 100000;
      }
    } else {
      this.setLabelsOneContribution();
      this.appendContribution(v4());
    }
  }

  cancel = () => {
    this.view.$emit('endProcess');
  }

  back = () => {
    this.amount_exceeded = false;
    this.set_state.item.contributions[0].amount = this.set_state.item.total_contribution.toString();
    this.set_state.item.contributions.pop();
  }

  nextStep = () => {
    this.set_state.item.total_contribution = parseCurrencyToNumber(
      this.set_state.item.contributions[0].amount,
    );
    if (this.set_state.item.total_contribution > this.maximum_amount) {
      this.amount_exceeded = true;
      this.wantsMoreThanOneContributionChange();
      this.firstDayChange();
    } else {
      this.nextStepMultiple();
    }
  }

  nextStepMultiple = () => {
    this.set_state.item.contributions_saved = true;
    this.set_state.item.dates_changed = true;
    this.view.$emit('nextStep');
  }

  suspendContribution = () => {
    this.view.$emit('suspendContribution');
  }

  wantsMoreThanOneContributionChange = () => {
    this.labels.first_amount = this.translate('contribution_amount');
    this.labels.first_payment_day = this.translate('first_payment_date');
    const desired_amount = parseCurrencyToNumber(this.set_state.item.contributions[0].amount);
    this.set_state.item.contributions[0].amount = this.maximum_amount.toString();
    this.appendContribution(v4());
    this.set_state.item.contributions[1].amount = (desired_amount - this.maximum_amount)
      .toString();
  }

  wantsBackToOneContributionChange = () => {
    if (this.set_state.item.wants_back_to_one_contribution) {
      this.minimum_for_multiple_contributions_not_covered = false;
      this.setLabelsOneContribution();
      this.set_state.item.current_payment_dates = this.translate('values_last_payment_dates', {
        first_day: this.recurring_contributions.contributions[0].collection_day,
        second_day: this.recurring_contributions.contributions[1].collection_day,
      });
      this.set_state.item.contributions[1].suspend = true;
    } else {
      this.setLabelsMultipleContributions();
      this.set_state.item.contributions[1].suspend = false;
      this.validateTotalOfContributions();
    }
  }

  validateTotalOfContributions = () => {
    const total_of_contributions = this.set_state.item.contributions.reduce(
      (sum, contribution) => sum + parseCurrencyToNumber(
        contribution.amount,
      ), 0,
    );
    this.minimum_for_multiple_contributions_not_covered = this.set_state.item.contributions
      .length > 1 && total_of_contributions < 50500;
  }

  changeFirstContribution = () => {
    this.validateTotalOfContributions();
  }

  changeSecondContribution = () => {
    this.validateTotalOfContributions();
  }

  loadAvailableDaysOnSecondCollectionDay = () => {
    const days = this.monthly_collection_days_service.load();
    this.second_monthly_collection_days = days.filter(
      (day) => this.monthly_collection_days_service
        .showDay(day, this.set_state.item.contributions[0].collection_day),
    );
  }

  firstDayChange = () => {
    this.loadAvailableDaysOnSecondCollectionDay();
    this.set_state.item.dates_changed = false;
  }

  initialize = async () => {
    this.set_state.is_loading = true;
    this.set_state.item.contributions_saved = false;
    await this.loadPeriods();
    this.loadMonthlyCollectionDays();
    await this.loadRecurringContributions();
    this.set_state.is_loading = false;
  }
}
